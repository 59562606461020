import React from 'react';

import { useStyles } from './styles';

const NoMatchingZonesMessages = ({ errorNoMatchingZone }) => {
  const classes = useStyles();
  return errorNoMatchingZone ? (
    <div className={classes.warning}>
      There is no matching zone in your offer for this set of points
    </div>
  ) : (
    <div className={classes.info}>
      Select at least one point on map to see dataset available for your
      selection.
    </div>
  );
};

export default React.memo(NoMatchingZonesMessages);

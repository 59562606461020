import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  warning: {
    background: palette.sinay.tertiary['90'],
    borderRadius: 8,
    padding: 10,
  },
  info: {
    background: palette.sinay.primary['90'],
    borderRadius: 8,
    padding: 10,
    marginBottom: 5,
  },
}));

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Database, Globe, PencilSimpleLine } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Switch } from '@material-ui/core';

import { trackZoneSelection } from 'ducks/trackers/actions/projectManagment';
import {
  requestMatchingZoneData,
  resetMatchingZoneData,
} from 'ducks/zones/actions';
import {
  selectMatchingZonesData,
  selectWorldMatchingZonesData,
  selectRegionalMatchingZonesData,
} from 'ducks/zones/selectors';
import { useModal } from 'hooks/useModal';
import { NEW_PROJECT_SELECT_DATASET_MODAL } from 'constants/modals';

import DatasetDetails from './DatasetDetails';
import NoMatchingZonesMessages from './NoMatchingZonesMessages';
import { useStyles } from './styles';

const NO_WORLD_ZONE = 'No World Zone';

const DatasetCard = ({
  type,
  selectedDatasetType,
  matchingZones,
  defaultSelectedDataset,
  handleSaveDataset,
  handleChangeDataset,
}) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const [
    forceSelectedDatasetDimension,
    setForceSelectedDatasetDimension,
  ] = useState(null);

  const handleSelectionDatasetDimension = useCallback((datasetDimension) => {
    setForceSelectedDatasetDimension(datasetDimension);
  });

  const openSelectDatasetModal = useCallback(() => {
    openModal(NEW_PROJECT_SELECT_DATASET_MODAL, {
      type,
      defaultSelectedDataset,
      matchingZones,
      handleUpdateSelectedDataset,
    });
  }, [defaultSelectedDataset, matchingZones]);

  const handleUpdateSelectedDataset = useCallback((updatedDataset) => {
    handleSaveDataset(type, updatedDataset);
  });

  const handleClick = useCallback(() => {
    if (matchingZones?.length > 0) {
      handleChangeDataset(defaultSelectedDataset, type);
    }
  });

  return (
    <div
      className={classNames(classes.itemCard, {
        [classes.selectedItemCard]: selectedDatasetType === type,
        [classes.clickableItemCard]:
          selectedDatasetType !== type &&
          defaultSelectedDataset !== NO_WORLD_ZONE &&
          matchingZones.length > 0,
        [classes.disabledItemCard]:
          selectedDatasetType !== type &&
          ((type === 'worldwide' && defaultSelectedDataset === NO_WORLD_ZONE) ||
            (type === 'regional' && matchingZones.length === 0)),
      })}
      onClick={handleClick}
    >
      <div className={classes.itemRowContainer}>
        <div className={classes.itemTitle}>
          {type === 'regional' ? (
            <>
              <Database size={20} /> High-Resolution{' '}
              <div className={classes.regionalTag}>Regional</div>
            </>
          ) : (
            <>
              <Globe size={20} /> Global Data{' '}
              <div className={classes.worldwideTag}>Worldwide</div>
            </>
          )}
        </div>
        {matchingZones?.length > 1 && (
          <PencilSimpleLine
            size={20}
            className={classes.cursor}
            onClick={openSelectDatasetModal}
          />
        )}
      </div>
      {defaultSelectedDataset && selectedDatasetType === type && (
        <DatasetDetails
          dataset={defaultSelectedDataset}
          forceSelectedDatasetDimension={forceSelectedDatasetDimension}
          handleSelectionDatasetDimension={handleSelectionDatasetDimension}
        />
      )}
    </div>
  );
};

const DatasetCards = ({
  allMatchingZonesIds,
  allWorldMatchingZones,
  allRegionalMatchingZones,
  selectedDatasetType,
  defaultRegionalDataset,
  defaultWorlwideDataset,
  handleSaveDataset,
  handleChangeDataset,
}) => {
  const [displayCards, setDisplayCards] = useState(false);

  // Check all data is available before display cards
  useEffect(() => {
    const ready = Boolean(
      allMatchingZonesIds.length ===
        allWorldMatchingZones.length + allRegionalMatchingZones.length &&
        defaultWorlwideDataset
    );
    setDisplayCards(ready);
  }, [
    allMatchingZonesIds,
    allWorldMatchingZones,
    allRegionalMatchingZones,
    defaultWorlwideDataset,
    setDisplayCards,
  ]);

  return !displayCards ? (
    <CircularProgress size={25} thickness={3} />
  ) : (
    <>
      <DatasetCard
        key="regional"
        type="regional"
        selectedDatasetType={selectedDatasetType}
        matchingZones={allRegionalMatchingZones}
        defaultSelectedDataset={defaultRegionalDataset}
        handleSaveDataset={handleSaveDataset}
        handleChangeDataset={handleChangeDataset}
      />
      <DatasetCard
        key="worldwide"
        type="worldwide"
        selectedDatasetType={selectedDatasetType}
        matchingZones={allWorldMatchingZones}
        defaultSelectedDataset={defaultWorlwideDataset}
        handleSaveDataset={handleSaveDataset}
        handleChangeDataset={handleChangeDataset}
      />
    </>
  );
};

const NewProjectDataset = ({
  errorNoMatchingZone,
  selectedMatchingZoneId,
  allMatchingZonesIds,
  handleUpdateSelectedMatchingZone,
  handleToggleMapDataset,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedDatasetType, setSelectedDatasetType] = useState('worldwide');
  const [defaultRegionalDataset, setDefaultRegionalDataset] = useState(null);
  const [defaultWorlwideDataset, setDefaultWorldwideDataset] = useState(null);
  const [toggleMapDataset, setToggleMapDataset] = useState(false);

  const allMatchingZones = useSelector(selectMatchingZonesData);
  const allWorldMatchingZones = useSelector(selectWorldMatchingZonesData);
  const allRegionalMatchingZones = useSelector(selectRegionalMatchingZonesData);

  // Save details for selected dataset
  const selectedDataset = useMemo(
    () => allMatchingZones.find((item) => item.id === selectedMatchingZoneId),
    [allMatchingZones, selectedMatchingZoneId]
  );

  // Set default worldwide dataset
  useEffect(() => {
    if (allWorldMatchingZones) {
      const initWorldDefaultDataset =
        allWorldMatchingZones?.length > 0
          ? allWorldMatchingZones[0]
          : NO_WORLD_ZONE;
      setDefaultWorldwideDataset(initWorldDefaultDataset);
    }
  }, [allWorldMatchingZones]);

  // Get details data for each matching zone
  useEffect(() => {
    if (!errorNoMatchingZone && allMatchingZonesIds?.length > 0) {
      dispatch(resetMatchingZoneData()); // Reset when ids change
      allMatchingZonesIds.forEach((matchingZoneId) => {
        dispatch(requestMatchingZoneData(matchingZoneId));
      });
    }
  }, [dispatch, allMatchingZonesIds, errorNoMatchingZone]);

  // Set details for selected matching zone id
  useEffect(() => {
    if (selectedMatchingZoneId && allMatchingZones?.length > 0) {
      const selectedMatchingZoneDetails = allMatchingZones.find(
        (zone) => zone.id === selectedMatchingZoneId
      );
      if (selectedMatchingZoneDetails && !selectedMatchingZoneDetails.isWorld) {
        setDefaultRegionalDataset(selectedMatchingZoneDetails);
        setSelectedDatasetType('regional');
      }
    }
  }, [
    selectedMatchingZoneId,
    allMatchingZones,
    setDefaultRegionalDataset,
    setSelectedDatasetType,
  ]);

  // Set "worldwide" by default, else set "regional"
  useEffect(() => {
    if (selectedMatchingZoneId) {
      if (selectedDataset) {
        const selectedDatasetTrackingData = {
          id: selectedDataset?.id,
          name: selectedDataset?.name,
          description: selectedDataset?.description,
          shapefile: selectedDataset?.shapefile,
          has2DStatistics: selectedDataset?.has2DStatistics,
          isActive: selectedDataset?.isActive,
          isCyclonic: selectedDataset?.isCyclonic,
          isDemo: selectedDataset?.isDemo,
          isPrivate: selectedDataset?.isPrivate,
          isWorld: selectedDataset?.isWorld,
          geom: selectedDataset?.geom,
        };
        dispatch(trackZoneSelection(selectedDatasetTrackingData));
        setSelectedDatasetType(
          selectedDataset.isWorld ? 'worldwide' : 'regional'
        );
      }
    } else {
      setSelectedDatasetType('worldwide'); // reset
    }
  }, [
    dispatch,
    selectedDataset,
    selectedMatchingZoneId,
    setSelectedDatasetType,
  ]);

  const handleChangeDataset = useCallback((dataset, datasetType) => {
    if (selectedDatasetType !== datasetType) {
      setSelectedDatasetType(datasetType);
      handleUpdateSelectedMatchingZone(dataset);
    }
  });

  const handleSaveDataset = useCallback((datasetType, updatedDataset) => {
    setSelectedDatasetType(datasetType);
    handleUpdateSelectedMatchingZone(updatedDataset);
    if (datasetType === 'worldwide') setDefaultWorldwideDataset(updatedDataset);
    if (datasetType === 'regional') setDefaultRegionalDataset(updatedDataset);
  });

  const handleDisplayMapDataset = useCallback(() => {
    const updatedToggleValue = !toggleMapDataset;
    setToggleMapDataset(updatedToggleValue);
    handleToggleMapDataset(updatedToggleValue);
  }, [toggleMapDataset, setToggleMapDataset]);

  return (
    <div id="NewProjectDataset" className={classes.createProjectSection}>
      <div className={classes.subtitle}>Dataset Selection</div>
      <div className={classes.switchContainer}>
        <Switch
          name="displayMapDataset"
          value={toggleMapDataset}
          onChange={handleDisplayMapDataset}
          color="primary"
        />
        <p>Display datasets on the map</p>
      </div>

      {allMatchingZonesIds?.length === 0 ? (
        <NoMatchingZonesMessages errorNoMatchingZone={errorNoMatchingZone} />
      ) : (
        <DatasetCards
          allMatchingZonesIds={allMatchingZonesIds}
          allWorldMatchingZones={allWorldMatchingZones}
          allRegionalMatchingZones={allRegionalMatchingZones}
          selectedDatasetType={selectedDatasetType}
          defaultRegionalDataset={defaultRegionalDataset}
          defaultWorlwideDataset={defaultWorlwideDataset}
          handleSaveDataset={handleSaveDataset}
          handleChangeDataset={handleChangeDataset}
        />
      )}
    </div>
  );
};

NewProjectDataset.propTypes = {
  errorNoMatchingZone: PropTypes.bool,
  selectedMatchingZoneId: PropTypes.number,
  allMatchingZonesIds: PropTypes.array,
  handleUpdateSelectedMatchingZone: PropTypes.func,
  handleToggleMapDataset: PropTypes.func,
};

export default React.memo(NewProjectDataset);

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  createProjectSection: {
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 3,
  },
  itemCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 5,
    border: '1px solid #E5E7EB',
    borderRadius: 8,
    padding: 17,
    width: '100%',
  },
  selectedItemCard: {
    border: `2px solid ${palette.sinay.primary['50']}`,
  },
  clickableItemCard: {
    cursor: 'pointer',
  },
  disabledItemCard: {
    color: palette.sinay.neutral['40'],
    backgroundColor: palette.sinay.neutral['90'],
  },
  itemRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  itemTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    fontSize: 14,
  },
  regionalTag: {
    fontSize: 12,
    padding: 5,
    backgroundColor: palette.sinay.secondary['90'],
    borderRadius: 4,
    color: '#404040',
  },
  worldwideTag: {
    fontSize: 12,
    padding: 5,
    backgroundColor: palette.sinay.tertiary['90'],
    borderRadius: 4,
    color: '#404040',
  },
  cursor: {
    cursor: 'pointer',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    alignItems: 'center',
    marginBottom: 3,
    '& p': {
      marginBottom: 0,
      fontSize: 13,
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  detailsContainer: {
    margin: '8px 0',
  },
  itemName: {
    fontSize: 12,
    color: '#404040',
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    marginTop: 8,
    marginBottom: 10,
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  dimensionLabel: {
    textTransform: 'uppercase',
    color: palette.sinay.primary['40'],
    fontWeight: 600,
    fontSize: 14,
  },
  dimensionDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 5,
  },
  dimensionDetailsItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 5,
    fontSize: 11,
  },
  dimensionDetailsContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  dimensionDetailsItemLabel: {
    fontWeight: 600,
  },
}));
